import {
	MESSAGE_NO_AGENTS_AVAILABLE_DEFAULT, MESSAGE_STANDARD_INFO,
} from "./constants";

export const config = {
	isStandalone: true,
	apiKey: "63f7e4c0-d2c0-46e6-bf7a-dbd27a2ed164",
	messages: {
		//firstStartMessage: [{title: MESSAGE_STANDARD_INFO}],
		//secondStartMessage: [],
		// waitingAgentEventMessage: '',
		// cancelLiveChatMessage: {title: '', buttons: []},

	},
	enableFeedbackForAllAgents: true,
	hooks: {
		/** @Function: getFirstStartMessages
		 *  @Return: array => [{title: 'text'}, ...]
		 */
		getFirstStartMessages: (props) => {
			// return empty
		},

		/** @Function: getSecondStartMessages
		 *  @Return: array => [{title: 'text'}, ...]
		 */
		getSecondStartMessages: (props) => {
			return [{title: MESSAGE_STANDARD_INFO}];
		},

		/** @Function: getQueueType
		 *  @Return: integer
		 */
		// getQueueType: (props) => {},

		/** @Function: getWaitingAgentEventMessage
		 *  @Return: string
		 */
		getWaitingAgentEventMessage: (props) => {
			return MESSAGE_NO_AGENTS_AVAILABLE_DEFAULT;
		},

		/** @Function: getMessageAgentConnected
		 *  @Return: string
		 */
		getMessageAgentConnected: () => {
			// return empty
		},

		/** @Function: getMessageAfterAgentConnected
		 *  @Return: string
		 */
		getMessageAfterAgentConnected: (agentName) => {
			return "My name is "+ agentName +", I may need to ask you a few questions in order for me to better assist you!";
		},
	}
};