import React from 'react';
import OdezaAppStandalone from 'odeza-customer-chat-core/src/OdezaAppStandalone';
import {config} from './config';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import {QUEUE_NAME_BA_ADENA_HEALTH_SYSTEM_CS,} from "./constants";

import logo from "./images/image-20230801-155820.png";
import avatar from "./images/Adena Health logo_64x64.jpg";

function App() {

    const customRoutes = [
        {route: '/', startQueueName: QUEUE_NAME_BA_ADENA_HEALTH_SYSTEM_CS},
    ];

    const setLogosByQueue = (config, queueName) => {
        console.log(queueName)
        config.avatarUrl = avatar;
        config.chatLogo = logo;
    };

    const getStyleByQueue = (config, queueName) => {
        return <style>
            {
                ".template__href," +
                ".endChatButton," +
                ".endLiveChatButton," +
                ".appointment-button," +
                ".ask_name button {" +
                "  background-color: #025d90 !important;" +
                "}" +
                ".slick-prev:before, .slick-next:before {" +
                "  color: #025d90 !important;" +
                "}" +
                ".sendMessage polygon#Path {" +
                "  stroke: #025d90 !important;" +
                "  fill: #025d90 !important;" +
                "}" +
                ".btn_start_over," +
                ".go_back_button {" +
                "  color: #025d90 !important;" +
                "  background-color: unset !important;" +
                "}"
            }
        </style>
    }

    return (
        <Router>
            <Switch>
                {/* --- Custom routes --- */}
                {
                    customRoutes.map((record, key) => {
                        return <Route key={key} path={process.env.PUBLIC_URL + record.route}
                                      render={(props) => {
                                          config.startQueueName = record.startQueueName;
                                          setLogosByQueue(config, record.startQueueName);
                                          return <>
                                              {getStyleByQueue(config, record.startQueueName)}
                                              <base target="_target"/>
                                              <OdezaAppStandalone
                                                  {...props}
                                                  config={config}
                                                  startQueueName={record.startQueueName}
                                              />
                                          </>
                                      }}
                        />
                    })
                }
                {/*--- Main\Default route ---*/}
                {/*
				<Route path={process.env.PUBLIC_URL + "/:mrn?/:department?/:ref?/:username?"}
					   render={(props) => (
						   <OdezaAppStandalone
							   {...props}
							   config={config}
						   />
					   )}
				/>
				*/}
            </Switch>
        </Router>
    );
}

export default App;
